:root {
	--mainColor: #000E19;
	--mainColorLight: #000E19;
	--secondaryColor: #66CD0D;
	--textColor: #eee;
}

.logoImage{
    width: 100px;
    height: 80px;
    object-fit: contain;
    object-position: center;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 1rem 2rem;
	color: var(--textColor);
}

@media only screen and (min-width: 1024px) {

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 80px;
		padding: 1rem 2rem;
		color: var(--textColor);
		position: sticky;
		top: 0;
		z-index: 10;
		transition: background-color 0.3s ease; /* Add a smooth transition effect */
	}

	.header.sticky{
		position: fixed;
		top: 0;
		left: 0;
		background-color:#000E19;; 
		z-index: 1000; 
		width: 100%;
		transition: background-color 0.3s ease; 
	}
}


nav a {
	margin: 0 1.5rem;
	color: var(--textColor);
	text-decoration: none;
	font-size: 18.5px;
	font-weight: 550;
	font-family: Futura-Light-font !important;
}

nav a:hover {
	color: var(--secondaryColor);
}

.header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

.header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1023px) {
	.header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	.header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
		z-index: 99;
	}

	.header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}