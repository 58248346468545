
/* .Banner{
  width: 100%;
  height: 550px;
  background-image: url('../../Assets/banner-gogreen.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */


.bannerText{
    text-align: left;
    width: 50%;
    word-break: keep-all;
    position: absolute;
    top: 50%;
    left: 20px;
}

.aboutImage1{
    width: 80%;
    object-fit: contain;
    object-position: center;
}

.aboutImage2{
    width: 80%;
    object-fit: contain;
    object-position: center;
}

.ServiceCard{
    width: 80%;
    background-image: url('../../Assets/Servicescard.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    gap: 5px;
    border-radius: 10px;
  }

  .OddGrid{
    transform: translateY(10%);
  }

.sliderBox{
    width: 100%;
    background-image: url('../../Assets/Testimonial Card.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;  
    border-radius: 25px;
}

.slick-slide {
    margin-right: 15px;
  }

  .slick-track {
    height:300px;
  }
  
  #sliderScale{
    transform: scale(1);
  }

  #slidernonScale{
    transform: scale(0.9);
  }

  .newsletterCard{
    width: 100%;
    background-image: url('../../Assets/NewsletterCard.png');
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 25px;
  }

  .ContactCard{
    width: 80%;
    background-image: url('../../Assets/Servicescard.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
  }

  @media screen and (min-width: 601px) and (max-width: 900px)  {
    .ServiceCard{
    height: 100%;
    }
    .slick-slide {
      margin-right: 0;
    }
    #sliderScale{
      transform: scale(0.8);
    }
    .OddGrid{
      transform: translateY(0);
    }
  }


  @media screen and (min-width: 901px) and (max-width: 1025px)  {
    .ServiceCard{
      height: 100%;
       gap:0px;  
    }
    .slick-track {
      height:340px;
    }
  }


  @media screen and (max-width: 480px) {
    .bannerText{
      text-align: left;
      width: 80%;
      word-break: keep-all;
      position: absolute;
      top: 30%;
      left: 20px;
  }
  .slick-slide {
    margin-right: 0;
  }
  .ServiceCard{
    height: 400px;
  }
  .OddGrid{
    transform: translateY(0);
  }
  }


  .video-background-banner {
    width: 100%;
    height: 82vh;
    background-color: #000E19;
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit:cover;
    position: absolute;
    top: 0;
  }